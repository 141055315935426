<template>
    <div>
        <mdb-card class="mb-2  "
                  style="margin-top:-1rem">
            <mdb-card-body class="p-2">
                <mdb-btn tag="a"
                         size="sm"
                         outline="primary"
                         @click="$router.push('/product/purchase/list')">
                    <i class="fa fa-search"></i>
                    進貨單查詢
                </mdb-btn>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <datepicker label="進貨時間"
                                    v-model="purchase.day"
                                    :max="new Date()" />
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-select label="分店"
                                    v-model="storeList"
                                    @getValue="val=>purchase.id_store=val"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-input label="進貨人"
                                   disabled
                                   :value="$store.state.user.name" />
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="6">
                        <searchSelect placeholder="===請選擇==="
                                      :options="productList"
                                      v-model="select_product"></searchSelect>
                    </mdb-col>
                    <mdb-col md="2">
                        <mdb-btn size="sm"
                                 color="primary"
                                 @click="add_item()">
                            <i class="fa fa-plus"></i>
                            增加
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
                <div class="">
                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th style="width:5rem">#</th>
                                <th>商品名稱</th>
                                <th style="width:10rem">數量</th>
                                <th style="width:8rem"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(p,pk) in purchase.item_list"
                                :key="`p_${pk}`">
                                <td>{{pk+1}}</td>
                                <td>
                                    <small>{{productKeyList[p.id_product].code}}</small><br>
                                    {{productKeyList[p.id_product].name}}
                                </td>
                                <td>
                                    <mdb-input type="number"
                                               class="m-0"
                                               :min="1"
                                               v-model.number="p.num"></mdb-input>
                                </td>
                                <td>
                                    <mdb-btn color="danger"
                                             size="sm"
                                             @click="purchase.item_list.splice(pk,1)">
                                        <i class="fa fa-trash"></i>
                                    </mdb-btn>
                                </td>
                            </tr>
                            <tr v-if="purchase.item_list.length==0">
                                <td colspan="4">無資料</td>
                            </tr>
                            <tr v-else>
                                <td colspan="4">
                                    共{{purchase.item_list.length}}項，{{get_total_count}}個
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mdb-card-body>
        </mdb-card>
        <div class="text-right mb-4">
            <mdb-btn color="default"
                     @click="save_purchase()">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
    </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbSelect,
  mdbBtn,
} from "mdbvue";
import datepicker from "../../components/datepicker";
import SearchSelect from "../../components/SearchSelect";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbBtn,
    datepicker,
    SearchSelect,
  },
  data() {
    return {
      purchase: {
        date: "",
        item_list: [],
      },
      new_purchase: { id_product: "", num: 1 },
      storeList: [],
      productList: [{ text: "清單讀取中" }],
      productKeyList: [],
      adminKeyList: {},
      select_product: "",
    };
  },
  mounted() {
    this.date = this.$moment().format("Y-m-d");
    this.get_data();
  },
  computed: {
    get_total_count() {
      let total = 0;
      this.purchase.item_list.forEach((item) => {
        total += item.num;
      });
      return total;
    },
  },
  methods: {
    get_data() {
      let vue = this,
        query = [
          {
            name: "product",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
            sort: {
              name: "0"
            },
          },
          {
            name: "store",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
          },
          // 取出空的進貨單
          {
            name: "purchase",
          },
        ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          vue.purchase = res.data.purchaseEmpty;
          vue.purchase.id_admin = vue.$store.state.user.id;
          vue.$set(vue.purchase, "item_list", []);
          vue.purchase.day = vue.$moment().format("YYYY-MM-DD");
          vue.purchase.id_store = "";
          vue.storeList = res.data.storeList.map((item) => {
            item.text = item.name;
            item.value = item.id;
            item.selected = vue.$store.state.id_store == item.id;
            return item;
          });
          vue.productList = [];
          vue.productKeyList = [];
          res.data.productList.forEach((item) => {
            vue.productList.push({
              key: item.id,
              value: `${item.name} ( ${item.code} )`,
            });
            vue.productKeyList[item.id] = {
              name: item.name,
              code: item.code,
            };
            return item;
          });
          // console.info(res.data);
        });
    },
    add_item() {
      console.info("新增品項", this.select_product);
      if (this.select_product != "" && this.select_product != null) {
        let index = this.purchase.item_list.findIndex((item) => {
          return item.id_product == this.select_product;
        });
        if (index == -1) {
          this.purchase.item_list.push({
            id_product: this.select_product,
            num: 1,
          });
        }
      }
      this.select_product = "";
    },
    // 儲存進貨
    save_purchase() {
      let vue = this,
        msg = "";
      switch (true) {
        case vue.purchase.id_store == null:
          msg = "請選擇進貨分店";
          break;
        case vue.purchase.item_list.length == 0:
          msg = "請輸入進貨項目";
          break;
      }
      if (msg == "") {
        vue.$swal.fire({
          icon: "info",
          title: "確定要儲存進貨資料嗎?",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          preConfirm() {
            return vue.$store
              .dispatch("post_form", {
                payload: {
                  url: "product/purchase_process",
                  data: { data: JSON.stringify(vue.purchase) },
                },
              })
              .then((res) => {
                if (res.data.status == "ok") {
                  vue.$swal
                    .fire({
                      icon: "success",
                      title: "儲存完成",
                      timer: 1500,
                      showConfirmButton: false,
                    })
                    .then(() => {
                      location.reload();
                    });
                }
              });
          },
        });
      } else {
        vue.$swal.fire({
          title: msg,
          icon: "warning",
          timer: 1500,
        });
      }
    },
  },
};
</script>